export const SUBSCRIBER_LOGIN_REQUEST = "SUBSCRIBER_LOGIN_REQUEST";
export const SUBSCRIBER_LOGIN_SUCCESS = "SUBSCRIBER_LOGIN_SUCCESS";
export const SUBSCRIBER_LOGIN_FAIL = "SUBSCRIBER_LOGIN_FAIL";
export const SUBSCRIBER_LOGOUT = "SUBSCRIBER_LOGOUT";

export const SUBSCRIBER_REGISTER_REQUEST = "SUBSCRIBER_REGISTER_REQUEST";
export const SUBSCRIBER_REGISTER_SUCCESS = "SUBSCRIBER_REGISTER_SUCCESS";
export const SUBSCRIBER_REGISTER_FAIL = "SUBSCRIBER_REGISTER_FAIL";

export const SUBSCRIBER_DETAILS_REQUEST = "SUBSCRIBER_DETAILS_REQUEST";
export const SUBSCRIBER_DETAILS_SUCCESS = "SUBSCRIBER_DETAILS_SUCCESS";
export const SUBSCRIBER_DETAILS_FAIL = "SUBSCRIBER_DETAILS_FAIL";

export const SUBSCRIBER_UPDATE_PROFILE_REQUEST =
  "SUBSCRIBER_UPDATE_PROFILE_REQUEST";
export const SUBSCRIBER_UPDATE_PROFILE_SUCCESS =
  "SUBSCRIBER_UPDATE_PROFILE_SUCCESS";
export const SUBSCRIBER_UPDATE_PROFILE_FAIL = "SUBSCRIBER_UPDATE_PROFILE_FAIL";

export const SUBSCRIBER_LIST_REQUEST = "SUBSCRIBER_LIST_REQUEST";
export const SUBSCRIBER_LIST_SUCCESS = "SUBSCRIBER_LIST_SUCCESS";
export const SUBSCRIBER_LIST_FAIL = "SUBSCRIBER_LIST_FAIL";

export const SUBSCRIBER_DELETE_REQUEST = "SUBSCRIBER_DELETE_REQUEST";
export const SUBSCRIBER_DELETE_SUCCESS = "SUBSCRIBER_DELETE_SUCCESS";
export const SUBSCRIBER_DELETE_FAIL = "SUBSCRIBER_DELETE_FAIL";
