export const DASHBOARD_LIST_REQUEST = "DASHBOARD_LIST_REQUEST";
export const DASHBOARD_LIST_SUCCESS = "DASHBOARD_LIST_SUCCESS";
export const DASHBOARD_LIST_FAIL = "DASHBOARD_LIST_FAIL";

export const DASHBOARD_EARNINGBARGRAPH_REQUEST =
  "DASHBOARD_EARNINGBARGRAPH_REQUEST";
export const DASHBOARD_EARNINGBARGRAPH_SUCCESS =
  "DASHBOARD_EARNINGBARGRAPH_SUCCESS";
export const DASHBOARD_EARNINGBARGRAPH_FAIL = "DASHBOARD_EARNINGBARGRAPH_FAIL";

export const DASHBOARD_INCOME_REQUEST = "DASHBOARD_INCOME_REQUEST";
export const DASHBOARD_INCOME_SUCCESS = "DASHBOARD_INCOME_SUCCESS";
export const DASHBOARD_INCOME_FAIL = "DASHBOARD_INCOME_FAIL";
