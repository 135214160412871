export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCTALL_LIST_REQUEST = "PRODUCTALL_LIST_REQUEST";
export const PRODUCTALL_LIST_SUCCESS = "PRODUCTALL_LIST_SUCCESS";
export const PRODUCTALL_LIST_FAIL = "PRODUCTALL_LIST_FAIL";

export const PRODUCT_ARCHIVELIST_REQUEST = "PRODUCT_ARCHIVELIST_REQUEST";
export const PRODUCT_ARCHIVELIST_SUCCESS = "PRODUCT_ARCHIVELIST_SUCCESS";
export const PRODUCT_ARCHIVELIST_FAIL = "PRODUCT_ARCHIVELIST_FAIL";

export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const CREATE_SIMILAR_PRODUCT_REQUEST = "CREATE_SIMILAR_PRODUCT_REQUEST";
export const CREATE_SIMILAR_PRODUCT_SUCCESS = "CREATE_SIMILAR_PRODUCT_SUCCESS";
export const CREATE_SIMILAR_PRODUCT_FAIL = "CREATE_SIMILAR_PRODUCT_FAIL";
