export const CAROUSEL_LIST_REQUEST = "CAROUSEL_LIST_REQUEST";
export const CAROUSEL_LIST_SUCCESS = "CAROUSEL_LIST_SUCCESS";
export const CAROUSEL_LIST_FAIL = "CAROUSEL_LIST_FAIL";

export const CAROUSEL_DELETE_REQUEST = "CAROUSEL_DELETE_REQUEST";
export const CAROUSEL_DELETE_SUCCESS = "CAROUSEL_DELETE_SUCCESS";
export const CAROUSEL_DELETE_FAIL = "CAROUSEL_DELETE_FAIL";

export const CAROUSEL_CREATE_REQUEST = "CAROUSEL_CREATE_REQUEST";
export const CAROUSEL_CREATE_SUCCESS = "CAROUSEL_CREATE_SUCCESS";
export const CAROUSEL_CREATE_FAIL = "CAROUSEL_CREATE_FAIL";
export const CAROUSEL_CREATE_RESET = "CAROUSEL_CREATE_RESET";

export const CAROUSEL_UPDATE_REQUEST = "CAROUSEL_UPDATE_REQUEST";
export const CAROUSEL_UPDATE_SUCCESS = "CAROUSEL_UPDATE_SUCCESS";
export const CAROUSEL_UPDATE_FAIL = "CAROUSEL_UPDATE_FAIL";

export const CAROUSEL_DETAILS_REQUEST = "CAROUSEL_DETAILS_REQUEST";
export const CAROUSEL_DETAILS_SUCCESS = "CAROUSEL_DETAILS_SUCCESS";
export const CAROUSEL_DETAILS_FAIL = "CAROUSEL_DETAILS_FAIL";
